@tailwind base;
@tailwind components;
@tailwind utilities;
html{
  background-color: #282c34;
}

.animate-all { transition: all 0.5s ease; }
.animate-bg { transition: background-color 0.5s ease; }
.hide-horizontal-scrollbar {
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For Internet Explorer and Edge */
}
.hide-horizontal-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.grecaptcha-badge { visibility: hidden; }
.gradient-text {
  background: linear-gradient(90deg, #e3630d 0%, #0211e2 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.grid-cols-custom {
  grid-template-columns: 1fr 100px 1fr;
}